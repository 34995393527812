import React from 'react'

import { isAuthenticated } from './services/auth'

import Employees         from './pages/maintenance/employees'
import Company           from './pages/maintenance/company'
import Profile           from './pages/maintenance/profile'
import User              from './pages/maintenance/users'
import UserGroup         from './pages/maintenance/user-group'
import Occupations       from './pages/maintenance/occupation'
import PermissionsGroup  from './pages/maintenance/permissions-group'
import PermissionsUser   from './pages/maintenance/permissions-user'
import ReportBill        from './pages/reports/report-bill'
import Error404          from './pages/errors/404' 
import Bill              from './pages/maintenance/bill'
import Alert             from './pages/maintenance/alert'
import AlertAll          from './pages/maintenance/alerts-all'
import NewsletterSystem  from './pages/maintenance/newsletter-system'
import Providers         from './pages/maintenance/providers'
import BillsToPay        from './pages/maintenance/billstopay'
import CostCenter        from './pages/maintenance/cost-center'
import ChartOfAccounts   from './pages/maintenance/chart-of-accounts'
import PaymentConditions from './pages/maintenance/payment-conditions'
import Holidays          from './pages/maintenance/holidays'
import Departament       from './pages/maintenance/departament'
import Peoples           from './pages/maintenance/peoples'
import Raffle            from './pages/maintenance/raffle'
import Settings          from './pages/maintenance/settings'
import Transactions      from './pages/maintenance/transactions'

const getObject = (uri, id, props, idmenu) => {
    switch (uri) {
        case '/settings'              : return(<Settings />)   
        case '/transactions'          : return(<Transactions id={id} props={props}/>)  
        case '/employees'             : return(<Employees/>)  
        case '/raffle'                : return(<Raffle/>)   
        case '/peoples'               : return(<Peoples/>)   
        case '/company'               : return(<Company/>)   
        case '/profile'               : return(<Profile/>)   
        case '/users'                 : return(<User/>)   
        case '/user-groups'           : return(<UserGroup/>)   
        case '/bill'                  : return(<Bill/>)   
        case '/occupations'           : return(<Occupations/>)  
        case '/alert-all'             : return(<AlertAll/>)
        case '/newsletter-system'     : return(<NewsletterSystem/>)
        case '/provider'              : return(<Providers/>)
        case '/billstopay'            : return(<BillsToPay/>)
        case '/cost-centers'          : return(<CostCenter/>)
        case '/chart-of-accounts'     : return(<ChartOfAccounts/>)
        case '/payment-conditions'    : return(<PaymentConditions/>)
        case '/holidays'              : return(<Holidays/>)
        case '/alert'                 : return(<Alert id={id} props={props}/>)
        case '/usergroup/permissions' : return(<PermissionsGroup id={id} props={props}/>)   
        case '/user/permissions'      : return(<PermissionsUser id={id} props={props}/>)
        case '/departament'           : return(<Departament/>)
        case '/receipt'               : return(<ReportBill id={id} props={props}/>)
        default                       : return(<Error404/>) 
    }
}


export default function getComponent(uri,id,props, idmenu) {
    return ( isAuthenticated ? getObject(uri, id, props,idmenu) : <Error404/> )
}

 