import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import Draggable from 'react-draggable';

import { format } from 'date-fns'

import Dropzone from 'react-dropzone';
import 'react-image-gallery/styles/css/image-gallery.css';

import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';
import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalRaffle({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                      = useState(false)

    const [name, setName]                     = useState('')
    const [value, setValue]                   = useState(0)
    const [files, setFiles]                   = useState([])
    const [description, setDescription]       = useState('')
    const [subtitle, setSubtitle]             = useState('')
    const [numbers, setNumbers]               = useState(0)
    const [drawDate, setDrawDate]             = useState(null)
    const [instagram, setInstagram]           = useState('')
    const [facebook, setFacebook]             = useState('')
    const [whatsapp, setWhatsapp]             = useState('')
    const [pixel, setPixel]                   = useState('')
    const [ordermin, setOrdermin]             = useState(0)

    const setData = (obj,clear) => {    
        console.log(obj)
        setName(clear ? '' : obj.name) 
        setValue(clear ? '' : obj.value) 
        setFiles(clear ? [] : obj.images || []) 
        setDescription(clear ? '' : obj.description) 
        setNumbers(clear ? '' : obj.numbers) 
        setInstagram(clear ? '' : obj.instagram || '') 
        setFacebook(clear ? '' : obj.facebook || '') 
        setWhatsapp(clear ? '' : obj.whatsapp || '') 
        setSubtitle(clear ? '' : obj.subtitle) 
        setPixel(clear ? '' : obj.pixel)
        setOrdermin(clear ? 0 : obj.ordermin || 0)
        setDrawDate(clear ? '' : (obj?.drawdate === null ? '' : format(new Date(obj.drawdate),'yyyy-MM-dd')))
    }


    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = () => {
            //const base64String = reader.result.split(',')[1];
            resolve(reader.result);
          };
      
          reader.onerror = () => {
            reject(new Error('Failed to read file'));
          };
      
          reader.readAsDataURL(file);
        });
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-raffle'),document.querySelector('#submit-raffle').children[0].classList,document.querySelector('#submit-raffle').lastChild.nodeValue)

        try {

            const formData = new FormData();
            formData.append('name', name);
            formData.append('value', value);
            formData.append('file', JSON.stringify(files))
            formData.append('description', description);
            formData.append('numbers', numbers);
            formData.append('drawdate', drawDate);
            formData.append('facebook', facebook);
            formData.append('instagram', instagram);
            formData.append('whatsapp', whatsapp);
            formData.append('subtitle',subtitle)
            formData.append('pixel',pixel)
            formData.append('ordermin',ordermin)

            if (editState)
                await api.put('/raffle/' + data.idraffle, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                  })
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                ShowMessages(response.data)
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else
                                    loadingButton(false,document.querySelector('#submit-raffle'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-raffle'))
                        })                        
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-raffle')) 
                            ShowMessages(response);
                        })
            else
                await api.post('/raffle', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                  })
                         .then(response => {
                            ShowMessages(response)
                             if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-raffle'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-raffle'))
                        })
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-raffle')) 
                            ShowMessages(response);
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-raffle'))
            ShowMessages(response)
        }
    }
    
    const setImageMain = async(_key) => {
        let _files = files.map((file, key) => {
            if (_key === key)
                file.main = true
            return file
        })

        setFiles(_files)
    }

    useEffect(() => {
        setStatistic('modals\\modal-raffle')
    },[])

    useEffect(() => {
        if (editState && showModal) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    const handleFileUpload = async(acceptedFiles) => {
        let _files =[]

        for (const [idx, _file] of acceptedFiles.entries()) {
            await readFileAsBase64(_file).then((base64String) => {
                _files.push({
                    file     : base64String,
                    main     : false
                })
            }).catch((error) => {
                console.log(error)
            }); 
        }

        setFiles(files => files.concat(_files))
    };

    const deleteImage = (_img) => {
        const imagensAtualizadas = [...files];
        imagensAtualizadas.splice(_img, 1);
        setFiles(imagensAtualizadas);
    }

    return (
      
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="heigth530px width650" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}>  
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-award"></i> Propriedades do Sorteio
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth250px"/>
                    <div className={`${loading ? "hidden" : ""}`}>    
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"50%"}}>
                                <label>Descrição</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="name"
                                        id="name"
                                        type="text"
                                        required
                                        maxLength={150}
                                        errorMessage={{ required: "Por favor, informe um nome!" }}
                                        value={name}
                                        autoFocus={true}
                                        className="form-control "
                                        onChange={e => setName(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{width:"50%"}}>
                                <label>Sub-título</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="subtitle"
                                        id="subtitle"
                                        type="text"
                                        required={true}
                                        errorMessage={{ required: "Por favor, informe uma subdescrição!" }}
                                        value={subtitle}
                                        maxLength={100}
                                        className="form-control "
                                        onChange={e => setSubtitle(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"150px"}}>
                                <label>Valor</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="value"
                                        id="value"
                                        type="text"
                                        required={true}
                                        errorMessage={{ required: "Por favor, informe um valor!" }}
                                        value={value}
                                        className="form-control text-right"
                                        onChange={e => setValue(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{ width:"200px"}}>
                                <label>Total de Cotas</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="numbers"
                                        id="numbers"
                                        type="number"
                                        required={true}
                                        disabled={editState}
                                        errorMessage={{ required: "Por favor, informe uma quantidade de cotas!" }}
                                        value={numbers}
                                        className="form-control text-right"
                                        onChange={e => setNumbers(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{width:"200px"}}>
                                <label>Data do Sorteio</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="birthdate"
                                        id="birthdate"
                                        type="date"
                                        errorMessage={{ required: "Por favor, informe uma data do sorteio!" }}
                                        value={drawDate}
                                        className="form-control "
                                        onChange={e => setDrawDate(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{width:"200px"}}>
                                <label>Compra Mínima</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="ordermin"
                                        id="ordermin"
                                        type="number"
                                        value={ordermin}
                                        className="form-control "
                                        onChange={e => setOrdermin(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{width:"100%"}}>
                                <label>META PIXEL</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="pixel"
                                        id="pixel"
                                        type="text"
                                        maxLength={1500}
                                        value={pixel}
                                        autoFocus={true}
                                        className="form-control "
                                        onChange={e => setPixel(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>  
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"100%"}}>
                                <label>Instagram</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="name"
                                        id="name"
                                        type="text"
                                        maxLength={100}
                                        value={instagram}
                                        autoFocus={true}
                                        className="form-control "
                                        onChange={e => setInstagram(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{width:"100%"}}>
                                <label>Facebook</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="facebook"
                                        id="facebook"
                                        type="text"
                                        maxLength={100}
                                        value={facebook}
                                        autoFocus={true}
                                        className="form-control "
                                        onChange={e => setFacebook(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{width:"100%"}}>
                                <label>Whatsapp</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="whatsapp"
                                        id="whatsapp"
                                        type="text"
                                        maxLength={100}
                                        value={whatsapp}
                                        autoFocus={true}
                                        className="form-control "
                                        onChange={e => setWhatsapp(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>         
                        <div>
                            <label>Detalhes do Prêmio</label>
                            <div className="input-group">
                                <TextInput  
                                    name="detail"
                                    id="detail"
                                    type="text"
                                    rows="7"
                                    multiline 
                                    required={true}
                                    errorMessage={{ required: "Por favor, informe um detalhe!" }}
                                    value={description}
                                    className="form-control "
                                    onChange={e => setDescription(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>
                        <div>
                            <Dropzone onDrop={handleFileUpload} className="container" accept={{'image/jpg' : ['.jpg','.jpeg','.png','.bmp'],'video/mpeg' : ['.mpeg','.avi','.mp4']}}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                        <p>Arraste e solte as imagens ou clique aqui para selecioná-las</p>
                                </div>
                            )}
                            </Dropzone>
                            <div style={{display:"flex", marginTop:"15px", gap:"10px", maxWidth:"100%", overflowX:"auto"}}>
                                {files.map((_img, key) => {
                                    return (
                                        <div style={{borderRadius:"5px"}}>
                                            <div>
                                                {['video/mp4','video/mpeg'].includes(_img.file.split(';')[0].split(':')[1]) ? 
                                                    <div style={{border:"1px dashed #cacaca", padding:"5px", borderRadius:"5px"}}>
                                                        <video style={{width:"80px", height:"80px", borderRadius:"5px"}}controls>
                                                            <source src={_img.file} type="video/mp4"/>
                                                        </video>
                                                        <div style={{width:"100%", display:"flex", gap:"5px"}}>
                                                            <button  title="Excluir imagem" type="button" className='btn btn-sm btn-danger btn-delete-img' onClick={e => deleteImage(key)}> <i className='fa fa-trash'></i></button>
                                                            {_img.main ? ""
                                                                       :
                                                                <button title="Definir como imagem principal" type="button" className='btn btn-sm btn-success btn-delete-img' onClick={e => setImageMain(key)}> <i className='fa fa-check'></i></button>
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    <div style={{border:"1px dashed #cacaca", padding:"5px", borderRadius:"5px"}}>
                                                        <img alt="" src={_img.file} style={{width:"100px", height:"80px", borderRadius:"5px"}}/>
                                                        <div style={{width:"100%", marginTop:"5px", display:"flex", gap:"5px"}}>
                                                            <button title="Excluir imagem" type="button" className='btn btn-sm btn-danger btn-delete-img' onClick={e => deleteImage(key)}> <i className='fa fa-trash'></i></button>
                                                            {_img.main ? ""
                                                                       :
                                                                <button title="Definir como imagem principal" type="button" className='btn btn-sm btn-success btn-delete-img' onClick={e => setImageMain(key)}> <i className='fa fa-check'></i></button>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>)
                                })}
                            </div>
                        </div>

                                   
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-raffle" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}