import React, { useState,useEffect } from 'react'

import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'

export default function Error404()  {
    const [logo, setLogo]                       = useState(null)
    const getData = async(e) => {
        await api.get('/getSettings')
                 .then(response => {
                    setLogo(response.data.logo)
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }
    
    useEffect(() => {
        getData()
        setStatistic('EmailForgot')
    },[])

    return (
        <section>
            <div className="box">
                <img src={logo} alt={process.env.REACT_APP_NAME}/>
                <span><strong>404</strong></span>
                <span>Página não encontrada</span>
            </div>
        </section>
    )
}

        