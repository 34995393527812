import React, {useEffect, useState, useCallback} from 'react';

import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'

import Header from './Header'
import Sidebar from './Sidebar'
import KSContainer from './../../pages/layouts/Container'
import ChatUserList from './ChatUserList'
import ChatMessageContainer from './ChatMessageContainer'

import { NotificationContainer } from 'react-notifications'
import { socket } from './../../services/chatAPI'
import { ACCEPT_TERMS, ReadDataStorage } from '../../services/auth';
import ModalAcceptedTerms from '../modals/modal-accepted-terms';

const BasePage = props => {
    const [company, setCompany] = useState(-1)
    const [reset,setReset]      = useState(false)
    const [pages, setPages]     = useState(-1)
    const [reload, setReload]   = useState(false);
    const [logo, setLogo]       = useState(null)

    const getData = async(e) => {
        await api.get('/getSettings')
                 .then(response => {
                    setLogo(response.data.logo)
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        getData()
    },[])

    const handleReload = () => {
        setReload((state) => !state);
      };

    const hasTermsAccepted = useCallback(() => {
        return ReadDataStorage(ACCEPT_TERMS) === 'true';
      }, [reload]);

    useEffect(() => {
        setReset(true)
    },[company])

    useEffect(() => {
    
    },[pages])

    if (!hasTermsAccepted()) {
        return (
          <>
            <ModalAcceptedTerms showModal={true} handleReload={handleReload} />
            <NotificationContainer />
          </>
        );
      }

    return (
        <>
            <div className="wrapper">
                {/* <div style={{ bottom: '0', right: '0', position: 'absolute', zIndex: '2000' }}>
                    <FloatingGroup
                        size={Size.REGULAR}
                        direction={Direction.TOP}
                        spacing={100}
                        rootButtonContainerStyle={{ background: '#104e5a', margin: '0' }}
                        rootButtonElement={
                            <Button className="btn-suporte">
                                <i className="fa fa-plus" style={{ fontSize: '18px' }} />
                            </Button>
                            }
                        >
                        <FloatMenuItemButton
                            containerStyle={{ height: '55px' }}
                            icon={<i className="fab fa-whatsapp" style={{ fontSize: '38px' }} />}
                            buttonColor="#25D366"
                            onClick={() =>
                                window.open(
                                'https://api.whatsapp.com/send?phone=5562984383946&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20o%2055Zap',
                                )
                            }
                        />
                        <FloatMenuItemButton
                            containerStyle={{ display: 'none' }}
                            icon={<i className="fab fa-whatsapp" style={{ fontSize: '38px' }} />}
                            buttonColor="#4f5bd5"
                        />
                    </FloatingGroup>
                </div> */}
                <Header history={props.history} callbackCompany={setCompany}/>
                <Sidebar ActualCompany={company}/>
            
                <ChatUserList socket={socket}/>
            
                <section className="section-container">
                    <div id="content-tab" className={`${pages > 0 ? 'heigth100' : 'hidden'}`}>
                        <KSContainer reset={reset} callbackReset={setReset} callbackPages={setPages}/>
                        </div>  
                    <div className='box-base'>
                        <img alt="LOGO"id="main-logo" src={logo} style={{ maxWidth: 40 + '%', width: 40 + '%' }} />
                    </div> 
                    <ChatMessageContainer socket={socket}/>            
                </section>
            </div>
            <NotificationContainer />
        </>)
}

export default BasePage;
