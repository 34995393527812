import { create } from 'apisauce'
import { COMPANY, IDCOMPANY, IDUSER, IP, ReadDataStorage, USERNAME, CONTRACT } from './auth'

const apiStatistic = create({
    baseURL : process.env.REACT_APP_IP_STATISTICS + ':' + process.env.REACT_APP_PORT_STATISTICS,
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
})



apiStatistic.addAsyncRequestTransform(request => async () => {
    request.headers["idcompany"]     = ReadDataStorage(IDCOMPANY)
    request.headers["company"]       = ReadDataStorage(COMPANY)
    request.headers["version"]       = process.env.REACT_APP_VERSION
    request.headers["contract"]      = ReadDataStorage(CONTRACT)
    request.headers["ip"]            = localStorage.getItem(IP)
    request.headers["software"]      = process.env.REACT_APP_NAME
    request.headers["username"]      = ReadDataStorage(USERNAME)
    request.headers["iduser"]        = ReadDataStorage(IDUSER)
    request.headers["resolution"]    = window.screen.width + 'x' + window.screen.height
    request.headers["Ratio"]         = (window.screen.width * window.devicePixelRatio) + 'x' + (window.screen.height * window.devicePixelRatio)
})

const setStatistic = async(window) => {
    apiStatistic.post('/events',{window })
}

export default setStatistic