import React, {useState, useEffect} from 'react'

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { formatNumberToCurrency } from './../../common/utils'
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalTopThree({showModal, handleCloseModal, _data}) { 
    const [data, setData] = useState([])

    useEffect(() => {
        if (showModal) {
            console.log(_data)
            setData(_data || [])
            setStatistic('modals\\modal-top-three')
        }
    } ,[showModal])

 
    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog} dialogClassName="width550">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-trophy"></i> Top 3 Compradores
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div style={{display:"flex"}}>
                        <div className='box-shadow' style={{padding:"10px", fontSize:"20px", display:"flex", flexDirection:"column", gap:"5px", width:"100%"}}>
                            {data.map((_item, id) => {
                                return ( <div className='box-shadow' style={{display:"flex", gap:"10px"}}>
                                            <div>
                                                <i className='fa fa-trophy' style={{color:`${id === 0 ? '#daa520' : id === 1 ? '#c0c0c0' : '#cd7f32'}`, fontSize:"60px"}}/>
                                                <div style={{marginTop: "-55px", marginLeft: "28px", border: "1px solid #fff",color: "#fff", fontWeight: "500", width: "10px"}}>
                                                    {id + 1}
                                                </div>
                                            </div>
                                            <div style={{width:"100%"}}>
                                                <div style={{display:"flex", flexDirection:"column"}}>
                                                    <span style={{fontSize:"14px", fontWeight:"bold"}}>Nome</span>
                                                    <span>{_item?.name}</span>
                                                </div>
                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>Total Compra</span>
                                                        <span>{formatNumberToCurrency(_item?.total_amount)}</span>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column", textAlign:"center"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>Total de Compras</span>
                                                        <span>{_item?.total_purchases}</span>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column", textAlign:"center"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>Total de Números</span>
                                                        <span>{_item?.total_numbers}</span>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>  )
                            })}
                        </div>  
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                        <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </div>
                </Modal.Footer>
        </Modal>
    )
}