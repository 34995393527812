import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import Draggable from 'react-draggable';


import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import InputMask from 'react-input-mask';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'

import api from '../../services/api';
import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalPeople({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                      = useState(false)

    const [name, setName]                            = useState('') 
    const [phone, setPhone]                          = useState('')   
    const [birthdate, setBirthdate]                  = useState('')
    const [CPF, setCPF]                              = useState('')
    

    const setData = (obj,clear) => {    
        setName(clear ? '' : obj.name) 
        setPhone(clear ? '' : obj.phones[0].phone)   
        setBirthdate(clear ? '' : obj.birthdate ? obj.birthdate.substr(0,10) : '')
        setCPF(clear ? '' : obj.documents[0].value)
    }


    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-peoples'),document.querySelector('#submit-peoples').children[0].classList,document.querySelector('#submit-peoples').lastChild.nodeValue)

        try {
            let _data = {name, 
                         phone,   
                         birthdate, 
                         CPF}

            if (editState)
                await api.put('/people/' + data.idpeople, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                ShowMessages(response.data)
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else
                                    loadingButton(false,document.querySelector('#submit-peoples'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-peoples'))
                        })                        
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-peoples')) 
                            ShowMessages(response);
                        })
            else
                await api.post('/people', _data)
                         .then(response => {
                            ShowMessages(response)
                             if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-peoples'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-peoples'))
                        })
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-peoples')) 
                            ShowMessages(response);
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-peoples'))
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        setStatistic('modals\\modal-peoples')
    },[])

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="heigth330px width400" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}>  
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-users"></i> Propriedades do Cliente
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth250px"/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Nome completo</label>
                            <div className="input-group">
                                <TextInput  
                                    name="name"
                                    id="name"
                                    type="text"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe um nome!" }}
                                    value={name}
                                    autoFocus={true}
                                    className="form-control "
                                    onChange={e => setName(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>
                        <div>
                            <label>CPF</label>
                            <div className="input-group">
                                <InputMask  
                                    name="cpf"
                                    id="cpf"
                                    type="text"
                                    mask="999.999.999-99"
                                    required={true}
                                    errorMessage={{ required: "Por favor, informe um CPF!" }}
                                    value={CPF}
                                    className="form-control "
                                    onChange={e => setCPF(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>
                        <div>
                            <label>Data de Nascimento</label>
                            <div className="input-group">
                                <TextInput  
                                    name="birthdate"
                                    id="birthdate"
                                    type="date"
                                    required={true}
                                    errorMessage={{ required: "Por favor, informe uma data de nascimento!" }}
                                    value={birthdate}
                                    className="form-control "
                                    onChange={e => setBirthdate(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                            <div>
                                <label>Telefone</label>
                                <div className="input-group">
                                    <InputMask  
                                        name="phone"
                                        id="phone"
                                        type="text"
                                        required={true}
                                        errorMessage={{ required: "Por favor, informe um telefone!" }}
                                        value={phone}
                                        mask="(99) 99999-9999"
                                        className="form-control "
                                        onChange={e => setPhone(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                                   
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-peoples" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}