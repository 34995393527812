import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import CurrencyInput from '../../components/moneyInput';

import { v4 as uuidv4 } from 'uuid';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'
class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalWinningNumbers({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                = useState(false)

    const [number, setNumber]                  = useState('')
    const [value, setValue]                    = useState(0)
    const [uniqueValue, setUniqueValue]        = useState(0)
    const [type, setType]                      = useState(1)
    const [winningNumbers, setWinningNumbers]  = useState([])

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-winningNumbers'),document.querySelector('#submit-winningNumbers').children[0].classList,document.querySelector('#submit-winningNumbers').lastChild.nodeValue)
        await api.post('/set-winningNumbers', {winningNumbers, type, uniqueValue,idraffle : data.idraffle})
                 .then(response => {
                    ShowMessages(response)
                    if (response.ok) {  
                        if (response.data.status === 200) {
                            handleCloseModal()  
                            handleReload(true)    
                        } else 
                            loadingButton(false,document.querySelector('#submit-winningNumbers'))
                    } else
                        loadingButton(false,document.querySelector('#submit-winningNumbers'))
                })
                .catch( error => {
                    loadingButton(false,document.querySelector('#submit-winningNumbers'))
                    ShowMessages(error) 
                })
    }
    
    const addNumber = () => {
        if (number === '') {
            ShowMessages({status : 500, message : "Informe um número que será premiado!"})
            return
        }

        if (((value === '') || (value <= 0)) && (type === 1)) {
            ShowMessages({status : 500, message : "Informe um valor válido para o prêmio"})
            return
        }

        setWinningNumbers(winningNumbers => winningNumbers.concat({ id : uuidv4(), 
                                                                    number,
                                                                    value})) 
        setNumber('')    
        setValue(0)
    }

    const removeNumber = (id) => {
        const _remove = winningNumbers.filter((t, i) => {
            if (t.id !== id)
                return true;
        
            return false;
        });
        setWinningNumbers(_remove)
    }

    useEffect(() => {
        setUniqueValue(data?.winningnumbers?.uniqueValue || 0)
        setType(data?.winningnumbers?.type)
        setWinningNumbers(data?.winningnumbers?.winningNumbers ||[])
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-winning-numbers')
    } ,[])

   

    return (
        <Modal size='sm' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog} dialogClassName="width400">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-crown"></i> Números Premiados
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className='box-shadow'>
                        <ValidationForm> 
                            <div style={{display:"flex",gap:'20px', flexDirection:"column"}}>
                                <div style={{display:"flex", flexDirection:"column", border:"1px solid #cacaca", padding:"10px", borderRadius:"5px", marginTop:"10px", gap:"5px"}}>
                                    <div style={{marginTop:"-20px"}}>
                                        <span style={{background:"#fff"}}>Escolha uma opção abaixo</span>
                                    </div>
                                    <div style={{display:"flex",gap:"5px", cursor:"pointer"}} onClick={e => setType(0)}>
                                        <input type="radio" checked={type === 0} className='form-check-input' name="option"/><span>Premiação Única para todas as cotas</span>
                                    </div>
                                    <div style={{display:"flex",gap:"5px", cursor:"pointer"}} onClick={e => setType(1)}>
                                        <input type="radio" checked={type === 1} className='form-check-input' name="option"/><span>Premiação Diferenciada para cada cota</span>
                                    </div>
                                </div>
                                <div>
                                    {type === 0 && (
                                        <div style={{width:"100%", textAlign:"center"}}>
                                            <span style={{fontSize:"18px"}}>Premiação Única</span>
                                            <CurrencyInput className='form-control text-center'  type="text" value={uniqueValue} onChange={setUniqueValue}/>
                                        </div>
                                    )}
                                </div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"120px"}}>
                                        <span>Número</span>
                                        <input className='form-control' type="number" value={number} onChange={e => setNumber(e.target.value)}/>
                                    </div>
                                    <div style={{display:"flex",gap:'5px'}}>
                                        {type === 1 && (
                                            <div style={{width:"120px"}}>
                                                <span>Premiação da Cota</span>
                                                <CurrencyInput className='form-control' type="text" value={value} onChange={setValue}/>
                                            </div>
                                        )}
                                        <div style={{marginTop:"17px"}}>
                                            <button type="button" className='btn btn-success' onClick={e => addNumber()}><i className='fa fa-plus'/></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ValidationForm>

                        <div>
                            <div style={{display:"flex", marginTop:"10px", background:"#cacaca", padding:"5px", fontWeight:"bold"}}>
                                <div style={{width:"140px"}}><span>Número</span></div>
                                {type === 1 && (
                                    <div style={{width:"130px"}}><span>Premiação da Cota</span></div>
                                )}
                            </div>
                            {winningNumbers.map(_number => {
                                return (
                                    <div style={{display:"flex", padding:"3px 5px"}}>
                                        <div style={{width:"140px", display:"flex", alignItems:"center"}}>{_number.number}</div>
                                        {type === 1 && (
                                            <div style={{width:"130px", marginLeft:"10px", display:"flex", alignItems:"center"}}>{_number.value}</div>
                                        )}
                                        <div style={{width:"80px", display:"flex", justifyContent:"center"}}>
                                            <button className='btn btn-sm btn-danger' onClick={e => removeNumber(_number.id)}><i className='fa fa-ban'/></button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-winningNumbers" onClick={e => handleSubmit(e)} className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}