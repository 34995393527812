import React, {useState, useEffect} from 'react'

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalWinningNumbersResult({showModal, handleCloseModal, data}) { 
    const [dataWinning, setDataWinning] = useState([])

    useEffect(() => {
        if (showModal) {
            setDataWinning(data || [])
            setStatistic('modals\\modal-winning-number-result')
        }
    } ,[showModal])

 
    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog} dialogClassName="width600">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-medal"></i> Ganhadores dos Números Premiados
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div style={{display:"flex"}}>
                        <div className='box-shadow' style={{padding:"10px", fontSize:"20px", display:"flex", flexDirection:"column", gap:"5px", width:"100%"}}>
                            {dataWinning.map((_item, id) => {
                                return ( <div className='box-shadow' style={{display:"flex", gap:"10px"}}>
                                            <div style={{width:"100%"}}>
                                                <div style={{display:"flex"}}>
                                                    <div style={{display:"flex", flexDirection:"column", width:"150px"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>Número</span>
                                                        <span>{_item?.number}</span>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>Nome</span>
                                                        <span>{_item?.name}</span>
                                                    </div>
                                                </div>
                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"start"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>Data da Compra</span>
                                                        <span>{_item?.booking_date}</span>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"start"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>CPF</span>
                                                        <span>{_item?.cpf}</span>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"start"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>Nascimento</span>
                                                        <span>{_item?.birthdate}</span>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"start"}}>
                                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>Telefone</span>
                                                        <span>{_item?.phones}</span>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>  )
                            })}
                        </div>  
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <div style={{display:"flex", justifyContent:"end", width:"100%"}}>
                        <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </div>
                </Modal.Footer>
        </Modal>
    )
}