import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'
import ModalNumbers from '../modals/modal-numbers';
import { format,endOfWeek, startOfWeek  }        from 'date-fns'
import { Col } from 'reactstrap';
import { formatNumberToCurrency } from './../../common/utils'

export default function Raffle(props) {
    const [loading, setLoading]                            = useState(false)
    const [showNumbers, setShowNumbers]                    = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [typeData, setTypeData]                          = useState(0) 
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([{ field      : 'create_at',
                                                                         key        : 'between',  
                                                                         value      : format(startOfWeek(new Date()),'yyyy-MM-dd'),
                                                                         value2     : format(endOfWeek(new Date()),'yyyy-MM-dd'),
                                                                         ignoretime : true    
                                                                        }])
    const [startDate, setStartDate]                        = useState(format(startOfWeek (new Date()),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(endOfWeek (new Date()),'yyyy-MM-dd'))
    const [filterStatus, setFilterStatus]                  = useState('')
    const [numbers, setNumbers]                            = useState([])

    const getTransaction = async() => {
        await api.get('/statustransaction/0')
                 .then(response => {
                     setGridParams({idtransaction     : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                       value : '00000'},
                                                        primaryKey  : true                          },
                                    name              : {text        : "Nome",
                                                         searchDefault : true},
                                    value             : {text        : "Valor",
                                                         className   : 'text-right',
                                                         isMoney     : true,
                                                         item        : {
                                                            className : 'text-right'
                                                         }},
                                    numbers           : {text        : "Quant. de Nºs",
                                                         className   : 'text-right',
                                                         item        : {
                                                            className : 'text-right'
                                                         }},
                                    paymentmethod     : {text        : 'Método de Pagamento'} ,
                                    dischargedate     : {text        : 'Data do Pagamento',
                                                         format      : 'dd/MM/yyyy HH:mm:ss'} ,
                                    statustransaction : {text        : "Status",
                                                         item        : {className : "text-center",
                                                                        switch    : {data  : response.data,
                                                                                     key   : 'idstatustransaction'}}},
                                    Options           : {text        : "Opções",
                                                         className   : "text-center",
                                                         order       : false,
                                                         filter      : false,
                                                         item        : [{type  : "Custom",
                                                         props : {tag            : "button",
                                                                  title          : "Visualizar Números",
                                                                  className      : 'btn btn-sm btn-success',
                                                                  icon           : "fa fa-list-ol",
                                                                  visualConditional : {idstatustransaction : {operator : '==',
                                                                                        values : 2}},
                                                                  indexcallback  : 0}},
                                                                         ],
                                                                       
                                                                                       
                                                        }})},                                                   
                     
                  )
                  .catch( error => { 
                      ShowMessages(error)
                  })
        
    }

    const getTotalize = async() => {
        await api.post('/totalize')
                 .then(response => {
                    setData(response.data[0])
                 })
                 .catch( error => { 
                      ShowMessages(error)
                 })
        
    }


    useEffect(() => {
        getTransaction()
        getTotalize()
        setStatistic('maintenance\\transactions')
    },[])
    

    const handleFilter               = () => {setIsOpenFilter(!isOpenFilter)}
    const handleNumbers              = () => {setShowNumbers(false)}

    const viewNumbers = async(id) => {
        await api.post('/shownumbers',{id})
                 .then(response => {
                    setNumbers(response.data)
                    setShowNumbers(true)
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    }

    const handleSearch = () => {
       let _filter = [{ field      : typeData === 0 ? 'create_at' : 'dischargedate',
                        key        : 'between',  
                        value      : new Date(startDate),
                        value2     : new Date(endDate),
                        ignoretime : true    
                      }]

        if (filterStatus !== '')
            _filter.push({
                field      : 'idstatustransaction',
                key        : '=',  
                value      : filterStatus
            })

        setFilters(_filter)
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalNumbers showModal={showNumbers} handleCloseModal = {handleNumbers}  data={numbers} />
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading" style={{display:"flex"}}>
                    <div style={{display:"flex", gap:"5px", marginLeft:"5px",  marginTop: "-12px"}}>
                        <div style={{display:"flex", gap:"10px", width:"320px"}}>
                            <div style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                                <div>
                                    <label>Tipo de Data</label>
                                    <select className='form-select' value={typeData} onChange={e => setTypeData(e.target.value)}>
                                        <option value={0}>Transação</option>
                                        <option value={1}>Pagamento</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Data Inicial</label>
                                    <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
                                </div>
                                <div>
                                    <label>Data Final</label>
                                    <input type="date" className='form-control' value={endDate} onChange={e => setEndDate(e.target.value)}/>
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <div>
                                    <label>Status</label>
                                    <select className='form-select' value={filterStatus} onChange={e => setFilterStatus(e.target.value)}>
                                        <option value={''}>Todos</option>
                                        <option value={0}>Aguardando Pagamento</option>
                                        <option value={1}>Cancelada</option>
                                        <option value={2}>Pago</option>
                                    </select>
                                </div>
                                <div style={{marginTop:"20px"}}>
                                    <button className='btn btn-success' title="Filtrar" onClick={e => handleSearch(e)}><i className='fa fa-filter'/></button>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,5)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </div>
                            </div>
                        </div>                       
                    </div>
                    <div style={{width:"100%", marginLeft:"15px"}}>
                        <div style={{display:"flex",flexWrap:"wrap", width:"100%", gap:"5px"}}>
                            <Col style={{width:"13%"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"5px"}}>
                                    <div className="col-4 d-flex align-items-center justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px", background:"#cacaca"}}>
                                        <em className="fa fa-money-bill-transfer fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-light rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{formatNumberToCurrency(data.openednow)}</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Aberto Hoje</div>
                                    </div>
                                </div>
                            </Col>  
                            <Col style={{width:"13%"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"5px"}}>
                                    <div className="col-4 d-flex align-items-center justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px", background:"#cacaca"}}>
                                        <em className="fa fa-hand-holding-dollar fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-light rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{formatNumberToCurrency(data.paidoutnow)}</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Pago Hoje</div>
                                    </div>
                                </div>
                            </Col> 
                            <Col style={{width:"13%", marginLeft:"6px", borderLeft:"1px dashed #cacaca", paddingLeft : "9px"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"5px"}}>
                                    <div className="col-4 d-flex align-items-center bg-danger-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
                                        <em className="fa fa-money-bill-transfer fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-danger rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{formatNumberToCurrency(data.opened)}</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Aberto</div>
                                    </div>
                                </div>
                            </Col>  
                            <Col style={{width:"13%"}} >
                                <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"5px"}}>
                                    <div className="col-4 d-flex align-items-center bg-success-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
                                        <em className="fa fa-hand-holding-dollar fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-success rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                    <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{formatNumberToCurrency(data.paidout)}</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Pago</div>
                                    </div>
                                </div>  
                            </Col>
                            <Col style={{width:"13%"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"5px"}}>
                                    <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
                                        <em className="fa fa-money-bill-trend-up fa-2x"></em>
                                    </div>  
                                    <div className="col-8 py-1 bg-primary rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{formatNumberToCurrency(data.total)}</div>
                                            <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Geral</div>
                                        </div>
                                    </div>
                            </Col>
                            <Col style={{width:"13%"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"5px"}}>
                                    <div className="col-4 d-flex align-items-center bg-success-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
                                        <em className="fa fa-list-ol fa-2x"></em>
                                    </div>  
                                    <div className="col-8 py-1 bg-success rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{data.numbers}</div>
                                            <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Nº Vendidos</div>
                                        </div>
                                    </div>
                            </Col>
                        </div>
                        <div style={{display:"flex",flexWrap:"wrap", width:"100%", gap:"5px"}}>
                            <Col style={{width:"13%"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"5px"}}>
                                    <div className="col-4 d-flex align-items-center justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px", background:"#cacaca"}}>
                                        <em className="fa fa-list-ol fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-light rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{data.numbersnow}</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Nº Vend. Hoje</div>
                                    </div>
                                </div>
                            </Col> 
                            <Col style={{width:"13%"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"5px"}}>
                                    <div className="col-4 d-flex align-items-center justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px", background:"#cacaca"}}>
                                        <em className="fa fa-arrow-down-1-9 fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-light rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{data.percentnow}%</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>% Vend. Hoje</div>
                                    </div>
                                </div>
                            </Col> 
                            <Col style={{width:"13%", marginLeft:"6px", borderLeft:"1px dashed #cacaca", paddingLeft : "9px"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0">
                                    <div className="col-4 d-flex align-items-center bg-warning-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
                                        <em className="fab fa-pix fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-warning rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{formatNumberToCurrency(data.paidoutpix)}</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>PIX</div>
                                    </div>
                                </div>
                            </Col>  
                            <Col style={{width:"13%"}} >
                                <div className="card flex-row align-items-center align-items-stretch border-0">
                                    <div className="col-4 d-flex align-items-center bg-warning-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
                                        <em className="fa fa-credit-card fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-warning rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{formatNumberToCurrency(data.paidoutcard)}</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Cartão</div>
                                    </div>
                                </div>  
                            </Col>
                            <Col style={{width:"13%"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0">
                                    <div className="col-4 d-flex align-items-center bg-warning-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
                                        <em className="fa fa-barcode fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-warning rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{formatNumberToCurrency(data.paidoutboleto)}</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>Boleto</div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{width:"13%"}}>
                                <div className="card flex-row align-items-center align-items-stretch border-0" >
                                    <div className="col-4 d-flex align-items-center bg-warning-dark justify-content-center rounded-left" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
                                        <em className="fa fa-arrow-down-1-9 fa-2x"></em>
                                    </div>
                                    <div className="col-8 py-1 bg-warning rounded-right" style={{borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                                        <div className="h3 mt-0 text-center" style={{fontSize:"20px"}}>{data.percent}%</div>
                                        <div className="text-uppercase text-center" style={{fontSize:"16px"}}>% Vendido</div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>

                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/transactions' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      returnData          = {setData}
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      boxClassName        = {'grid-transaction'}
                      filename            = "Transações"
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      perPage             = {10}
                      callbackCellClick   = {undefined}
                      callbackButtons     = {[viewNumbers]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}