import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalIncreaseNumbers({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]      = useState(false)

    const [numbers,setNumbers]       = useState(0)
    const [newnumbers,setNewNumbers] = useState(0)

    const setData = (obj,clear) => {
        setNumbers(clear ? '' : obj.numbers) 
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-increase-numbers'),document.querySelector('#submit-increase-numbers').children[0].classList,document.querySelector('#submit-increase-numbers').lastChild.nodeValue)
        try {
            let _data = {newnumbers,
                         idraffle : data.idraffle}

            await api.post('/increase-numbers', _data)
                      .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-increase-numbers'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-increase-numbers'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-increase-numbers'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-increase-numbers'))
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-increase-numbers')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-list-ol"></i> Incluir novos números
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Números Atuais</label>
                            <div className="input-group">
                                <TextInput  
                                    name="numbers"
                                    id="numbers"
                                    type="text"
                                    disabled
                                    autoFocus={true}
                                    maxLength={100}
                                    value={numbers}
                                    className="form-control "
                                    autoComplete="off"/>
                            </div>
                        </div>   
                        <div>
                            <label>Nova Quantidade</label>
                            <div className="input-group">
                                <TextInput  
                                    name="newnumbers"
                                    id="newnumbers"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe a nova quantidade!" }}
                                    value={newnumbers}
                                    className="form-control "
                                    onChange={e => setNewNumbers(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>                                   
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-increase-numbers" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}