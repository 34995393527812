import React, {useState, useEffect} from 'react'

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import setStatistic from '../../services/apiStatistic'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalNumbers({showModal, handleCloseModal, data}) {
    const [loading, setLoading]                                  = useState(false)
   
    useEffect(() => {
        setStatistic('modals\\modal-numbers')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog} dialogClassName="width555">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-list-ol"></i> Números por Transação
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth250px"/>
                    <div className='box-shadow' style={{display:"flex", gap:"5px", flexWrap:"wrap", width:"525px", alignItems:"center", justifyContent:"center"}}>               
                        {data.map(_number => {
                            return <div style={{borderRadius:"5px", padding:"5px"}} className='bg bg-success'>{_number.number}</div>
                        })

                        }
                                                              
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-departament" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}