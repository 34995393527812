import React, {useState, useEffect} from 'react'

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'
import { useFetcher } from 'react-router-dom';
import { createNumberMask } from 'text-mask-addons';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalAward({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]      = useState(false)

    const [numbers,setNumbers]            = useState('')
    const [name, setName]                 = useState('')
    const [phone, setPhone]               = useState('')
    const [phoneMask, setPhoneMask]       = useState('')
    const [birthDate, setBirthDate]       = useState('')
    const [cpf, setCPF]                   = useState('')
    const [cpfMask, setCPFMask]           = useState('')
    const [totalNumbers, setTotalNumbers] = useState(0)
    const [datePurchase, setDatePurchase] = useState('')
    const [idpeople, setIDPeople]         = useState(0)
    const [number, setNumber]             = useState('')

    const [isSearch, setIsSearch]         = useState(false)

    const [showCPF, setShowCPF]           = useState(false)
    const [showPhone, setShowPhone]       = useState(false)

    const clear = (clearSearch) => {
        setName('')
        setPhone('')
        setBirthDate('')
        setCPF('')
        setTotalNumbers(0)
        setDatePurchase('') 
        setPhoneMask('') 
        setCPFMask('')  
        setNumber('')
        setIDPeople(0)
        if (clearSearch)
            setIsSearch(false)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        if (idpeople === 0)  {
            ShowMessages({status : 500, message : "Nenhum cliente foi encontrado"})
            return
        }

        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente tornar o cliente listado como ganhador?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/setaward',{idraffle : data.idraffle, idpeople, number} )
                         .then(response => {
                            ShowMessages(response.data)
                            handleCloseModal()
                            handleReload()
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 

        
    }
    
    useEffect(() => {
        if ((data.closed) && (data.idpeople !== null)) {
            search(data?.numberwinner)
        } else {
            clear(true)
        }

    },[data,editState,showModal])
   
    useEffect(() => {
        if (numbers.length === 0) {
            clear(true)
        }
    },[numbers])

    useEffect(() => {
        setStatistic('modals\\modal-award')
    } ,[])

    const search = async(_number) => {
        setLoading(true)
        await api.post('/search-numbers', {numbers : _number, idraffle : data.idraffle})
                 .then(response => {
                    if (response.ok) {   
                            setShowPhone(false)
                            setShowCPF(false)
                            setIsSearch(true)
                            if (response.data) {
                                setName(response.data.name)
                                setPhone(response.data.phones)
                                setPhoneMask(response.data.phones.substr(0,11) + '****')
                                setBirthDate(response.data.birthdate)
                                setCPF(response.data.cpf)
                                setCPFMask(response.data.cpf.substr(0,3) + '.***'+ response.data.cpf.substr(7,12))
                                setTotalNumbers(response.data.total)
                                setDatePurchase(response.data.booking_date)     
                                setIDPeople(response.data.idpeople)
                                setNumber(numbers)
                            } else {
                                clear(false)
                            }
                            setLoading(false) 
                    }
                })
                .catch( error => {
                    ShowMessages(error) 
                })
    }

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog} dialogClassName="width550">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-award"></i> Pesquisa de Ganhadores
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className={`${data.closed && data.idpeople !== null ? '': 'hidden'}`}>
                        <div className='box-shadow' style={{padding:"10px", fontSize:"22px"}}>
                            <div className='bg bg-success' style={{padding:"15px", textAlign:"center", borderRadius:"5px", marginBottom:"10px"}}>
                                <i className='fa fa-award'/>
                                <span style={{marginLeft:"5px"}}>{data.numberwinner}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", lineHeight:"20px"}}>
                                <span style={{fontSize:"16px", fontWeight:"bold"}}>Nome</span>
                                <span>{name}</span>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                <span style={{fontSize:"16px", fontWeight:"bold"}}>Telefone</span>
                                <span>{phone}</span>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                    <span style={{fontSize:"16px", fontWeight:"bold"}}>CPF</span>
                                    <span>{cpf}</span>
                                </div>
                                <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                    <span style={{fontSize:"16px", fontWeight:"bold"}}>Nascimento</span>
                                    <span>{birthDate}</span>
                                </div>
                            </div>

                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                    <span style={{fontSize:"16px", fontWeight:"bold"}}>Data da Compra</span>
                                    <span>{datePurchase}</span>
                                </div>
                                <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                    <span style={{fontSize:"16px", fontWeight:"bold"}}>Total de Números</span>
                                    <span style={{textAlign:"right"}}>{totalNumbers}</span>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className={`${data.closed && data.idpeople !== null ? 'hidden': ''}`}>               
                        <div>
                            <div>
                                <label style={{fontSize:"28px"}}>Informe o número do ganhador</label>
                                <div  style={{display:"flex"}}>
                                    <div className="input-group">
                                        <input  
                                            name="numbers"
                                            id="numbers"
                                            type="text"
                                            autoFocus={true}
                                            maxLength={6}
                                            value={numbers}
                                            onChange={e => setNumbers(e.target.value)}
                                            className="form-control input-large"
                                            autoComplete="off"/>
                                    </div>  
                                    <div style={{marginLeft:"5px"}}>
                                        <button type="button" onClick={e => search(numbers)} className='btn btn-primary' disabled={loading} style={{width:"56px", height:"56px"}}><i className={`fa ${loading ? 'fa-spin fa-spinner' : 'fa-magnifying-glass'}`}/></button>
                                    </div>
                                </div>

                            </div>
                            
                        </div> 

                        {(name === '') && (isSearch)? 
                            <div style={{padding:"15px", marginTop:"15px", borderRadius:"5px", fontSize:"18px", textAlign:"center"}} className='bg bg-warning'>
                                <span style={{color:"#000"}}>O número informado não foi adquirido. </span>
                            </div>   
                        : (isSearch) && (
                            <div className='box-shadow' style={{padding:"10px", fontSize:"22px", marginTop:"10px"}}>
                                <div style={{display:"flex", flexDirection:"column", lineHeight:"20px"}}>
                                    <span style={{fontSize:"16px", fontWeight:"bold"}}>Nome</span>
                                    <span>{name}</span>
                                </div>
                                <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                    <span style={{fontSize:"16px", fontWeight:"bold"}}>Telefone</span>
                                    <div style={{display:"flex",alignItems:"center"}}>
                                        <span>{showPhone ? phone :  phoneMask}</span>
                                        <button type='button' className='btn btn-sm btn-warning' style={{marginLeft:"5px"}} onClick={e => setShowPhone(!showPhone)}><i className={`fa ${showPhone ? 'fa-eye-slash': 'fa-eye'}`} style={{marginLeft:"-2px"}}/></button>
                                    </div>
                                </div>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                        <span style={{fontSize:"16px", fontWeight:"bold"}}>CPF</span>
                                        <div style={{display:"flex",alignItems:"center"}}>
                                            <span>{showCPF ? cpf : cpfMask}</span>
                                            <button type='button' className='btn btn-sm btn-warning' style={{marginLeft:"5px"}} onClick={e => setShowCPF(!showCPF)}><i className={`fa ${showCPF ? 'fa-eye-slash': 'fa-eye'}`} style={{marginLeft:"-2px"}}/></button>
                                        </div>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                        <span style={{fontSize:"16px", fontWeight:"bold"}}>Nascimento</span>
                                        <span>{birthDate}</span>
                                    </div>
                                </div>

                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                        <span style={{fontSize:"16px", fontWeight:"bold"}}>Data da Compra</span>
                                        <span>{datePurchase}</span>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"column", lineHeight:"20px", marginTop:"15px"}}>
                                        <span style={{fontSize:"16px", fontWeight:"bold"}}>Total de Números</span>
                                        <span style={{textAlign:"right"}}>{totalNumbers}</span>
                                    </div>
                                </div>
                            </div>   
                        )}                          
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                        {data?.closed && data?.idwinner === null ? 
                            <Button type="submit" onClick={e => handleSubmit(e)} className={`btn-success ${loading ? "hidden" : ""}`}>
                                <em className="fa fa-award"></em> Definir Como Ganhador
                            </Button>          
                        : ""}
                        <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </div>
                </Modal.Footer>
        </Modal>
    )
}