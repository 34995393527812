import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'
import MaskWithValidation from '../../components/maskInput';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalChartOfAccount({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                                  = useState(false)

    const [account,setAccount]                                    = useState('')
    const [chartofaccount,setChartofaccount]                      = useState('')
    const [findstheresult,setFindstheresult]                      = useState(false)
    const [idstatuschartofaccount,setIdstatuschartofaccount]      = useState('')
    const [idnaturechartofaccount,setIdnaturechartofaccount]      = useState('')

    const [dataNatureChartOfAccount, setdataNatureChartOfAccount] = useState([])
    const [dataStatusChartOfAccount, setdataStatusChartOfAccount] = useState([])

    const setData = (obj,clear) => {
        setAccount(clear ? '' : obj.account) 
        setChartofaccount(clear ? '' : obj.chartofaccount)
        setFindstheresult(clear ? false : obj.findstheresult)
        setIdstatuschartofaccount(clear ? '' : obj.idstatuschartofaccount)
        setIdnaturechartofaccount(clear ? '' : obj.idnaturechartofaccount)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-chart-of-account'),document.querySelector('#submit-chart-of-account').children[0].classList,document.querySelector('#submit-chart-of-account').lastChild.nodeValue)
        try {
            let _data = {account,
                         chartofaccount,
                         findstheresult,
                         idstatuschartofaccount,
                         idnaturechartofaccount}

            if (editState)
                await api.put('/chart-of-account/' + data.idchartofaccount, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-chart-of-account'))
                         } else
                             loadingButton(false,document.querySelector('#submit-chart-of-account'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-chart-of-account'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/chart-of-account', _data)
                      .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-chart-of-account'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-chart-of-account'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-chart-of-account'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-chart-of-account'))
            ShowMessages(response)
        }
    }
    
    const getData = async() => {
        await api.get('/getChartOfAccountParentData')
            .then(response => {
                if (response.ok) {
                    setdataNatureChartOfAccount(response.data.naturechartofaccount)  
                    setdataStatusChartOfAccount(response.data.statustofaccount)
                }
        }).catch(response => {
            ShowMessages(response)
        })
    } 

    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-chart-of-account')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-tasks"></i> Propriedades do Plano de Contas
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Conta</label>
                            <div className="input-group">
                                <MaskWithValidation
                                    name="account"
                                    id="account"
                                    type="text"
                                    required
                                    errorMessage={{ required: "Por favor, informe uma conta!" }}
                                    className="form-control "
                                    value={account}
                                    autoFocus={true}
                                    onChange={e => setAccount(e.target.value)}
                                    autoComplete="off"
                                    mask={[/[0-9]/,'.',/[0-9]/,'.',/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/]}/>
                            </div>
                        </div> 
                        <div>
                            <label>Descrição</label>
                            <div className="input-group">
                                <TextInput  
                                    name="chartofaccount"
                                    id="chartofaccount"
                                    type="text"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe uma descrição!" }}
                                    value={chartofaccount}
                                    className="form-control "
                                    onChange={e => setChartofaccount(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>   
                        <div style={{display:"flex"}}>
                            <div style={{width:"50%"}}>
                                <label>Status</label>
                                <div>
                                    <SelectGroup 
                                        name="idstatuschartofaccount" 
                                        id="idstatuschartofaccount" 
                                        required 
                                        errorMessage={{ required: "Por favor, informe um status!" }}
                                        className="form-select" 
                                        value={idstatuschartofaccount} 
                                        onChange={e => setIdstatuschartofaccount(parseInt(e.target.value))}>
                                        <option  value=''>Selecione</option>
                                        {dataStatusChartOfAccount.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div>   
                            <div style={{marginLeft:"15px", width:"50%"}}>
                                <label>Natureza</label>
                                <div>
                                    <SelectGroup 
                                        name="idnaturechartofaccount" 
                                        id="idnaturechartofaccount" 
                                        required 
                                        errorMessage={{ required: "Por favor, informe uma natureza!" }}
                                        className="form-select"
                                        value={idnaturechartofaccount} 
                                        onChange={e => setIdnaturechartofaccount(parseInt(e.target.value))}>
                                        <option  value=''>Selecione</option>
                                        {dataNatureChartOfAccount.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div>                               
                        </div>
                        <div style={{display:"flex",flexDirection:"row-reverse",justifyContent: "flex-end"}}>
                            <label style={{ margin: "5px 0px 0px 5px" }}>Conta de Resultado?</label>
                            <label className="switch switch-sm" >
                                <input type="checkbox" checked={findstheresult} value={findstheresult} onClick={e => setFindstheresult(!findstheresult)}  />
                                <span style={{ marginTop: "5px" }}></span>
                            </label>
                        </div> 
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-chart-of-account" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}