import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog, Alert } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import CurrencyInput from './../../components/moneyInput';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'
import TooltipItem from '../../components/tooltip';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalPaymentConditions({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                        = useState(false)

    const [paymentcondition,setPaymentCondition]       = useState('')
    const [idkindofpayment,setIdkindOfPayment]         = useState('')
    const [fees,setFees]                               = useState('')
    const [fine,setFine]                               = useState('')
    const [generatefinancial,setGenerateFinancial]     = useState(false)
    const [maxdiscount,setMaxDiscount]                 = useState('')
    const [installments,setInstallments]               = useState([])
    const [allowEdit, setAllowEdit]                    = useState(false)

    const [installment, setInstallment]                = useState('')
    const [day, setDay]                                = useState('')
    const [addition, setAddition]                      = useState('')

    const [dataKindOfPayment, setDataKindOfPayment]    = useState([])
    const [messageInstallment, setMessageInstallment]  = useState('')

    const getData = async() => {
        setLoading(true)
        try {
            await api.get('/getPaymentConditionsData')
                     .then(response => {
                        if (response.ok) 
                            setDataKindOfPayment(response.data.kindofpayment)
                     })
            
            setLoading(false)            
        } catch (response) {
            ShowMessages(response)    
        }
    }

    const setData = (obj,clear) => {
        setPaymentCondition(clear ? '' : obj.paymentcondition) 
        setIdkindOfPayment(clear ? '' : obj.idkindofpayment)
        setFees(clear ? 0 : obj.fees)
        setFine(clear ? 0 : obj.fine)
        setGenerateFinancial(clear ? false : obj.generatefinancial)
        setAllowEdit(clear ? false : obj.allowedit)
        setMaxDiscount(clear ? 0 : obj.maxdiscount)
        setInstallments(clear ? [] : obj.installments)
    }

    const addInstallments = () => {
        setMessageInstallment('')
        if ((day !== "") && (addition !== "") && (installment !== "")) {
            if (!installments.find(el => (el.installment === installment))) {
                setInstallments(installments => installments.concat({installment, 
                                                                     day,
                                                                     addition})) 
                setInstallment('')                                              
                setDay('')                                              
                setAddition('')
            } else 
                setMessageInstallment('Parcela informada já está cadastrada!')
        } else {
            if (installment === "")  {
                setMessageInstallment('Por favor, informe a parcela!')
                return
            }

            if (day === "") {
                setMessageInstallment('Por favor, informe os dias da parcela!')
                return
            }
            if (addition === "") {
                setMessageInstallment('Por favor, informe o acrescimo de cada parcela!')
                return
            } 

        }
        
    }

    const removeInstallments = (installment) => {
        const _remove = installments.filter((t, i) => {
            if (t.installment !== installment)
                return true;
        
            return false;
        });
        setInstallments(_remove)
    }

    const editInstallments = (installment) => {
        let _data = installments.find(el => el.installment === installment)

        setInstallment(_data.installment)                                              
        setDay(_data.day)                                              
        setAddition(_data.addition)

        removeInstallments(installment)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        try {
            let _data = {paymentcondition,
                         idkindofpayment,
                         installments,
                         fees,
                         fine,
                         generatefinancial,
                         allowEdit,
                         maxdiscount}

            if (editState)
                await api.put('/payments-conditions/' + data.idpaymentcondition, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-payment-conditions'))
                         } else
                             loadingButton(false,document.querySelector('#submit-payment-conditions'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-payment-conditions'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/payments-conditions/', _data)
                      .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-payment-conditions'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-payment-conditions'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-payment-conditions'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-payment-conditions'))
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-payment-conditions')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="height600 width500" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa fa-money-check"></i> Propriedades de Condição de Pagamento
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Condição de Pagamento</label>
                            <div className="input-group">
                                <TextInput  
                                    name="paymentcondition"
                                    id="paymentcondition"
                                    type="text"
                                    required
                                    autoFocus={true}
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe uma condição de pagamento!" }}
                                    value={paymentcondition}
                                    className="form-control "
                                    onChange={e => setPaymentCondition(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>
                        <div>
                            <label>Espécie</label>
                            <div>
                                <SelectGroup 
                                    name="idkindofperson" 
                                    id="idkindofperson" 
                                    required 
                                    errorMessage={{ required: "Por favor, informe uma espécie!" }}
                                    placeholder="Selecione" 
                                    value={idkindofpayment} 
                                    className="form-select"
                                    onChange={e => setIdkindOfPayment(parseInt(e.target.value))}>
                                    <option  value=''>Selecione</option>
                                    {dataKindOfPayment.map((data, id) => {
                                        return (<option key={id} value={data.value}>{data.label}</option>)
                                    })} 
                                </SelectGroup> 
                            </div>
                        </div>
                        <div style={{display:"flex", }}>
                            <div style={{width:"16%"}}>
                                <label>(%) Juros</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="fees"
                                        id="fees"
                                        type="text"
                                        value={fees}
                                        className="form-control text-right"
                                        onChange={setFees}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{marginLeft:"15px",width:"15%"}}>
                                <label>($) Multa</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="fine"
                                        id="fine"
                                        type="text"
                                        value={fine}
                                        className="form-control text-right"
                                        onChange={setFine}
                                        autoComplete="off"/>
                                </div>
                            </div>  
                            <div style={{marginLeft:"15px",width:"20%"}}>
                                <label>Desc. Max.(%)</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="maxdiscount"
                                        id="maxdiscount"
                                        type="text"
                                        value={maxdiscount}
                                        className="form-control text-right"
                                        onChange={setMaxDiscount}
                                        autoComplete="off"/>
                                </div>
                            </div> 
                            <div style={{display:"flex",flexDirection: "column", justifyContent:"center", marginLeft:"15px"}}>
                                <div style={{display:"flex",flexDirection:"row-reverse",justifyContent: "flex-end"}}>
                                    <label style={{marginLeft:"5px"}}>Gerar Financeiro</label>
                                    <label className="switch switch-sm">
                                        <input type="checkbox" checked={generatefinancial} value={generatefinancial} onClick={e => setGenerateFinancial(!generatefinancial)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                </div> 
                                <div style={{display:"flex",flexDirection:"row-reverse",justifyContent: "flex-end"}}>
                                    <label style={{marginLeft:"5px"}}>Permite Editar Parcela</label>
                                    <label className="switch switch-sm" >
                                        <input type="checkbox" checked={allowEdit} value={allowEdit} onClick={e => setAllowEdit(!allowEdit)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                </div> 
                            </div>
                        </div>    
                        <div style={{marginTop:"25px", border:"1px dashed #cacaca", padding:"10px", borderRadius:"5px"}}>
                            <div style={{display:"flex"}}>
                                <div style={{width:"115px",minWidth:"115px"}}>
                                    <label>Parcela</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="installment"
                                            id="installment"
                                            type="number"
                                            autoFocus={true}
                                            maxLength={100}
                                            value={installment}
                                            className="form-control "
                                            onChange={e => setInstallment(e.target.value)}
                                            autoComplete="off"/>
                                    </div>    
                                </div>
                                <div style={{marginLeft:"15px",width:"125px",minWidth:"125px"}}>
                                    <label>Dias</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="day"
                                            id="day"
                                            type="number"
                                            autoFocus={true}
                                            maxLength={100}
                                            value={day}
                                            className="form-control "
                                            onChange={e => setDay(e.target.value)}
                                            autoComplete="off"/>
                                    </div>    
                                </div>
                 
                                <div style={{marginLeft:"15px",width:"125px",minWidth:"125px"}}>
                                    <label>(%) Acres.</label>
                                    <div className="input-group">
                                        <CurrencyInput  
                                            name="addition"
                                            id="addition"
                                            type="text"
                                            value={addition}
                                            className="form-control text-right"
                                            onChange={setAddition}
                                            autoComplete="off"/>
                                    </div>    
                                </div>
                                <div style={{marginLeft:"15px", marginTop:"18px"}}>
                                    <TooltipItem  content="Click aqui para inserir uma nova parcela" className="background-info">
                                        <button type="button" className="btn btn-sm btn-success" onClick={e => addInstallments()}><i className="fa fa-arrow-down"></i></button>
                                    </TooltipItem>
                                </div>
                            </div>
                             
                            <div style={{marginTop:"15px"}}>
                                <Alert variant="danger" style={{marginTop: "5px", marginBottom: "15px"}} className={`${messageInstallment === "" ? "hidden" : ""}`}>
                                    {messageInstallment}
                                </Alert>

                                <div style={{ overflow:"auto", height:"180px"}}>       
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr className="head-th">
                                                <td className="head-th" style={{width:"85px", minWidth:"85px", maxWidth:"85px"}}>Parcela</td>
                                                <td className="head-th" style={{width:"70px", minWidth:"70px", maxWidth:"70px"}}>Dias</td>
                                                <td className="head-th" style={{width:"80px", minWidth:"80px", maxWidth:"75px"}}>(%) Acres.</td>
                                                <td style={{textAlign:"center", width:"60px"}}>
                                                    ...
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {installments.map((item, key) => {
                                                return (
                                                    <tr className="head-th" key={key}  onDoubleClick ={e => editInstallments(item.installment)}>
                                                        <td>{item.installment}</td>
                                                        <td>{item.day}</td>
                                                        <td>{item.addition}</td>
                                                        <td style={{textAlign:"center"}}>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={e => removeInstallments(item.installment)}><em className="fa fa-trash" ></em></button>
                                                            <button type="button" className="btn btn-warning  btn-sm" onClick={e => editInstallments(item.installment)} style={{marginLeft:"5px"}}><em className="fa fa-edit"></em></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}         
                                        </tbody>
                                    </table>
                                    <div className={`box-sem-dados bg-warning ${installments.length > 0 ? "hidden" : ""}`} style={{padding:"15px"}}>Ainda não foi incluido nenhuma parcela.</div>
                                </div>
                            </div>                             
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-payment-conditions" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}