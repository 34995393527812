import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import SEM_IMAGEM from './../../assets/sem-imagem.png'
import loadingButton  from './../layouts/Buttons'

import {  Button } from 'reactstrap';

export default function Settings(props) {
    const [loading, setLoading]                 = useState(false)
    const [loadingContent, setLoadingContent]   = useState(false)

    const [SEAE, setSEAE]                       = useState(0)
    const [SMTPPort, setSMTPPort]               = useState(0)
    const [SMTPServer, setSMTPServer]           = useState('')
    const [SMTPUser, setSMTPUser]               = useState('')
    const [SMTPPassword, setSMTPPassword]       = useState('')
    const [publicKey55Zap, setPublicKey55Zap]   = useState('')
    const [instagram, setInstagram]             = useState('')
    const [facebook, setFacebook]               = useState('')
    const [publicKeyMP, setPublicKeyMP]         = useState('')
    const [privateKeyMP,setPrivateKeyMP]        = useState('')
    const [phoneSupport,setPhoneSupport]        = useState("")
    const [acceptPIX, setAcceptPIX]             = useState(false)
    const [acceptBoleto, setAcceptBoleto]       = useState(false)
    const [acceptCard, setAcceptCard]           = useState(false)
    const [company, setCompany]                 = useState('')
    const [WebhookWhatsApp, setWebhookWhatsApp] = useState('')
    const [logo, setLogo]                       = useState(null)
    const [title, setTitle]                     = useState('')

    const onImageChange = (event ) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            let file = event.target.files[0];
            reader.onloadend = () => {
                setLogo(reader.result)
            };
            reader.readAsDataURL(file);
        }
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            let _data = { SEAE,
                          SMTPPort,
                          SMTPServer,
                          SMTPUser,
                          SMTPPassword,
                          publicKey55Zap,
                          instagram,
                          facebook,
                          publicKeyMP,
                          privateKeyMP,
                          phoneSupport,
                          acceptPIX,
                          acceptBoleto,
                          acceptCard,
                          WebhookWhatsApp,
                          logo,
                          title,
                          company}

            await api.post('/company/settings', _data)
                     .then(response => {
                        if (response.ok) 
                            ShowMessages(response.data)
                            setLoading(false)
                        })
        } catch (response) {
            setLoading(false)
            ShowMessages(response)
        }
    }

    const getData = async(e) => {
        setLoadingContent(true)
        await api.get('/getSettings')
                 .then(response => {
                    console.log(response.data)
                    setSEAE(response.data.SEAE)
                    setSMTPPort(response.data.SMTPPort)
                    setSMTPServer(response.data.SMTPServer)
                    setSMTPUser(response.data.SMTPUser)
                    setSMTPPassword(response.data.SMTPPassword)
                    setPublicKey55Zap(response.data.publicKey55Zap)
                    setInstagram(response.data.instagram)
                    setFacebook(response.data.facebook)
                    setPublicKeyMP(response.data.publicKeyMP)
                    setPrivateKeyMP(response.data.privateKeyMP)
                    setPhoneSupport(response.data.phoneSupport)
                    setAcceptPIX(response.data.acceptPIX)
                    setAcceptBoleto(response.data.acceptBoleto)
                    setAcceptCard(response.data.acceptCard)
                    setCompany(response.data.company)
                    setTitle(response.data.title)
                    setWebhookWhatsApp(response.data.WebhookWhatsApp)
                    setLogo(response.data.logo)
                    setLoadingContent(false)
                 })
                 .catch( error => { 
                    setLoadingContent(false)
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\settings')
    },[])
    


    return (    
        <ContentWrapper className="content-wrapper-bottom81 ">
            <Loading loading={loading} addClass="heigth60vh"/>
            <div className={`content-box-cart-page  ${(loading ? "hidden" : "")}`}>
                <Loading loading={loadingContent} addClass="heigth50vh"/>
                <div className={`${(loadingContent ? "hidden" : "")}`} style={{ height:"78vh", overflowY:"scroll", width: "100%", marginLeft: "25px", border: "1px solid #EAEAEA", padding: "20px", borderRadius: "5px", display:"flex", flexDirection:"column"}}>
                    <div style={{width:"175px"}}>
                                        <div alt="Click para incluir ou alterar uma logomarca" style={{cursor:'pointer', backgroundColor:"#F2F2F2", width:"125px", height:"125px",border:"1px solid #ced4da", borderRadius:"5px", justifyContent:"center",alignItems:"center",display:"flex"}} onClick={e => (document.querySelector('#logo').click())}>
                                            <input type="file" id="logo" style={{display:"none"}} accept="image/*" onChange={onImageChange}/>
                                            <img alt="" src={logo === null ? SEM_IMAGEM : logo} style={{maxWidth:"122px",maxHeight:"122px"}}/>
                                        </div>
                                        <div style={{marginTop:"5px"}}>
                                            <button type="button" className="btn btn-danger" style={{width:'125px'}} onClick={e => setLogo(null)}><i className="fa fa-trash"/> Remover</button>
                                        </div>
                                    </div>
                    <div>
                        <label>Nome da Empresa</label>
                        <input className='form-control' value={company} onChange={e => setCompany(e.target.value)}/>
                    </div>
                    <div>
                        <label>Título da Página</label>
                        <input className='form-control' value={title} onChange={e => setTitle(e.target.value)}/>
                    </div>
                    <div>
                        <label>Número de Autorização SEAE</label>
                        <input className='form-control' value={SEAE} onChange={e => setSEAE(e.target.value)}/>
                    </div>
                    <div>
                        <label>Servidor SMTP</label>
                        <input className='form-control' value={SMTPServer} onChange={e => setSMTPServer(e.target.value)}/>
                    </div>
                    <div>
                        <label>Porta SMTP</label>
                        <input className='form-control' value={SMTPPort} onChange={e => setSMTPPort(e.target.value)}/>
                    </div>
                    <div>
                        <label>Usuário SMTP</label>
                        <input className='form-control' value={SMTPUser} onChange={e => setSMTPUser(e.target.value)}/>
                    </div>
                    <div>
                        <label>Senha SMTP</label>
                        <input className='form-control' value={SMTPPassword} onChange={e => setSMTPPassword(e.target.value)}/>
                    </div>
                    <div>
                        <label>Chave Publica Chatbot</label>
                        <input className='form-control' value={publicKey55Zap} onChange={e => setPublicKey55Zap(e.target.value)}/>
                    </div>
                    <div>
                        <label>Instagram</label>
                        <input className='form-control' value={instagram} onChange={e => setInstagram(e.target.value)}/>
                    </div>
                    <div>
                        <label>Facebook</label>
                        <input className='form-control' value={facebook} onChange={e => setFacebook(e.target.value)}/>
                    </div>
                    <div>
                        <label>Chave Pública MP</label>
                        <input className='form-control' value={publicKeyMP} onChange={e => setPublicKeyMP(e.target.value)}/>
                    </div>
                    <div>
                        <label>Chave Privada MP</label>
                        <input className='form-control' value={privateKeyMP} onChange={e => setPrivateKeyMP(e.target.value)}/>
                    </div>
                    <div>
                        <label>Whatsapp Suporte</label>
                        <input className='form-control' value={phoneSupport} onChange={e => setPhoneSupport(e.target.value)}/>
                    </div>
                    <div>
                        <label>WebHook Para Envio de Números no WhatsApp</label>
                        <input className='form-control' value={WebhookWhatsApp} onChange={e => setWebhookWhatsApp(e.target.value)}/>
                    </div>
                    <div>
                        <label>Meios de Pagamento</label>
                        <div style={{display:"flex"}}>
                            <label className="switch switch-sm" style={{ justifyContent: "center" }} >
                                <input type="checkbox" checked={acceptPIX} value={acceptPIX} onClick={ e => setAcceptPIX(!acceptPIX)}/>
                                <span style={{ marginTop: "5px" }}></span>
                            </label>
                            <span style={{marginLeft:"10px", marginTop:"5px"}}>PIX</span>
                        </div>
                        <div style={{display:"flex"}}>
                            <label className="switch switch-sm" style={{ justifyContent: "center" }} >
                                <input type="checkbox" checked={acceptBoleto} value={acceptBoleto} onClick={ e => setAcceptBoleto(!acceptBoleto)}/>
                                <span style={{ marginTop: "5px" }}></span>
                            </label>
                            <span style={{marginLeft:"10px", marginTop:"5px"}}>Boleto</span>
                        </div>
                        <div style={{display:"flex"}}>
                            <label className="switch switch-sm" style={{ justifyContent: "center" }} >
                                <input type="checkbox" checked={acceptCard} value={acceptCard} onClick={ e => setAcceptCard(!acceptCard)} />
                                <span style={{ marginTop: "5px" }}></span>
                            </label>
                            <span style={{marginLeft:"10px", marginTop:"5px"}}>Cartão</span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button type="submit" className={`btn-theme ${loading ? "hidden" : ""}`} onClick={e => handleSubmit(e)}>
                    <em className="fa fa-save"></em> Salvar
                    </Button>
                <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${props.id}`,e)}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}