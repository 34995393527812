import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalRaffle from '../modals/modal-raffle';
import ModalIncreaseNumbers from '../modals/modal-increase-numbers';
import ModalTopThree from '../modals/modal-top-three';
import ModalPromotions from '../modals/modal-promotions';
import ModalAward from '../modals/modal-award';
import ModalWinningNumbers from '../modals/modal-winning-numbers';
import ModalWinningNumbersResult from '../modals/modal-winning-numbers-result';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'
import Swal from 'sweetalert2'

export default function Raffle() {
    const [loading, setLoading]                                             = useState(false)
    const [showModal, setShowModal]                                         = useState(false)
    const [showModalWinningNumbers, setShowModalWinningNumbers]             = useState(false)
    const [showModalWinningNumbersResult, setShowModalWinningNumbersResult] = useState(false)
    const [showModalIncrease,setShowModalIncrease]                          = useState(false)
    const [showModalAward,setShowModalAward]                                = useState(false)
    const [showModalPromotions, setShowModalPromotions]                     = useState(false)
    const [showModalTopThree, setShowModalTopThree]                         = useState(false)
    const [isOpenFilter, setIsOpenFilter]                                   = useState(false)
    const [reload, setReload]                                               = useState(false)
    const [print, setPrint]                                                 = useState(false)
    const [excel, setExcel]                                                 = useState(false)
    const [showButtons, setShowButtons]                                     = useState(false)
    const [editState, setEditState]                                         = useState(false)
    const [gridParams, setGridParams]                                       = useState({})
    const [data, setData]                                                   = useState([])
    const [filters, setFilters]                                             = useState([])

    const [editPermission, setEditPermisson]                                = useState(false)

    const getRaffle = async() => {
        let _delCheck  = !checkPermission(4,1)
        let _editCheck = !checkPermission(8,1)
        setEditPermisson(_editCheck)

        setGridParams({idraffle         : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                        value : '00000'},
                                           primaryKey  : true                          },
                       name             : {text        : "Nome",
                                           searchDefault : true},
                       value            : {text        : "Valor",
                                           className   : 'text-right',
                                           isMoney     : true,
                                           item        : {
                                                className : 'text-right'
                                           }},
                       numbers          : {text        : 'Números',
                                           className   : 'text-right',
                                           item        : {
                                                className : 'text-right'
                                           }},
                       available        : {text        : 'Disponíveis',
                                           className   : 'text-right',
                                           item        : {
                                                className : 'text-right'
                                           }},
                       percentagesold   : {text        : '% Vendido',
                                           className   : 'text-right',
                                           item        : {
                                                className : 'text-right'
                                           }},
                       billed           : {text        : '($) Faturado',
                                           isMoney     : true,
                                           className   : 'text-right',
                                           item        : {
                                                className : 'text-right'
                                           }},
                       drawdate         : {text        : 'Data do Sorteio'},
                       closed           : {text        : "Encerrada?",
                                           item        : {
                                                className : 'text-center'
                                           }},
                       Options          : {text        : "Opções",
                                           className   : "text-center",
                                           order       : false,
                                           filter      : false,
                                           item        : [{type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Ganhadores da Cota Premiada",
                                                                    disabled       : _editCheck,
                                                                    className      : 'btn btn-sm btn-success btn-success-blink',
                                                                    icon           : "fa fa-medal",
                                                                    visualConditional : {winner : {operator : '>',
                                                                                                   values : 0}},
                                                                    indexcallback  : 9}},
                                                    
                                                    
                                                           {type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Exclusão de sorteio",
                                                                    className      : 'btn btn-sm btn-danger',
                                                                    icon           : "fa fa-trash",
                                                                    disabled       : _delCheck,
                                                                    visualConditional : [{closed : {operator : '==',
                                                                                                    values : 'Não'},
                                                                                          billed : {operator : '!==',
                                                                                                    values : 0}}],
                                                                    indexcallback  : 0}},
                                                          {type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Editar sorteio",
                                                                    disabled       : _editCheck,
                                                                    className      : 'btn btn-sm btn-warning',
                                                                    icon           : "fa fa-edit",
                                                                    visualConditional : {closed : {operator : '==',
                                                                                         values : 'Não'}},
                                                                    indexcallback  : 1}},
                                                          {type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Aumentar números do sorteio",
                                                                    className      : 'btn btn-sm btn-secondary',
                                                                    icon           : "fa fa-list-ol",
                                                                    visualConditional : {closed : {operator : '==',
                                                                                         values : 'Não'}},
                                                                    indexcallback  : 2}},
                                                          {type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Encerrar Sorteio",
                                                                    className      : 'btn btn-sm btn-primary',
                                                                    icon           : "fa fa-lock",
                                                                    visualConditional : {closed : {operator : '==',
                                                                                                    values : 'Não'}},
                                                                    indexcallback  : 3}},
                                                           {type  : "Custom",
                                                            props : {tag            : "button",
                                                                     title          : "Transações",
                                                                     className      : 'btn btn-sm btn-theme',
                                                                     icon           : "fa fa-money-bill-wave",
                                                                     indexcallback  : 5}},                                                           
                                                           {type  : "Custom",
                                                                     props : {tag             : "dropdown",
                                                                              menus           : [{
                                                                                                    icon          : 'fa fa-award',
                                                                                                    text          : 'Pesquisar Ganhador',
                                                                                                    indexcallback : 4
                                                                                                 },
                                                                                                 {
                                                                                                    icon          : 'fa fa-trophy',
                                                                                                    text          : 'Top 3 Ganhadores',
                                                                                                    indexcallback : 7
                                                                                                 },
                                                                                                 {
                                                                                                    icon          : 'fa fa-percent',
                                                                                                    text          : 'Promoções',
                                                                                                    indexcallback : 6,
                                                                                                    visualConditional : {closed : {operator : '==',
                                                                                                                                   values : 'Não'}},
                                                                                                 },
                                                                                                 {
                                                                                                    icon          : 'fa fa-crown',
                                                                                                    text          : 'Incluir Números Premiados',
                                                                                                    indexcallback : 8,
                                                                                                 },
                                                                                                ],
                                                                              title          : "Lista de Opções Financeiras",
                                                                              className      : 'btn btn-sm btn-success',
                                                                              icon           : "fa fa-bars",
                                                                              visualConditional : {closed : {operator : '==',
                                                                                                  values : 'Não'}},
                                                                              indexcallback  : 6}}]}
                                             
                 })
    }

    useEffect(() => {
        getRaffle()
        setStatistic('maintenance\\raffle')
    },[])
    
    const handleCloseModal                     = () => {setShowModal(false)}
    const handleCloseModalIncrease             = () => {setShowModalIncrease(false)}
    const handleCloseModalPomotions            = () => {setShowModalPromotions(false)}
    const handleCloseModalWinningNumber        = () => {setShowModalWinningNumbers(false)}
    const handleCloseModalWinningNumberResult  = () => {setShowModalWinningNumbersResult(false)}
    const handleCloseModalAward                = () => {setShowModalAward(false)}
    const handleCloseModalTopThree             = () => {setShowModalTopThree(false)}
    const handleFilter                         = () => {setIsOpenFilter(!isOpenFilter)}

    const finnancial = async(id) => {
        let props = {
            id 
        }
        await api.get('/raffle/' + id)
                 .then(response => {
                    window.addTab(null, 'Transações | ' + response.data.name, 'fa fa-money-bill-wave', '/transactions',props,0)
                })
                .catch( error => {
                    ShowMessages(error)
                })
    }

    const winningNumbers = async(id) => {
        await api.get('/raffle/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModalWinningNumbers(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const winningNumbersResult = async(id) => {
        await api.get('/winning-numbers-result/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModalWinningNumbersResult(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const topThree = async(id) => {
        await api.get('/topthree/' + id)
                 .then(response => {
                    console.log(response.data)
                    setData(response.data)
                    setShowModalTopThree(true)
                 })
                .catch( error => {
                    ShowMessages(error)
                })
    }

    const searchAward = async(id) => {
        await api.get('/raffle/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModalAward(true)
                 })
                .catch( error => {
                    ShowMessages(error)
                })
    }
    
    const RemoveRaffle = async (id) => {   
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o sorteio?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/raffle/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    const EditRaffle = async(id) => {
        await api.get('/raffle/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const IncreaseNumber = async(id) => {
        await api.get('/raffle/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModalIncrease(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const closeRaffle = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente encerrar o sorteio?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/close-raffle',{idraffle : id})
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    const promotions = async(id) => {
        await api.get('/raffle/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModalPromotions(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalRaffle showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload}/>
            <ModalIncreaseNumbers showModal={showModalIncrease} handleCloseModal = {handleCloseModalIncrease} editState={editState} data={data} handleReload={setReload}/>
            <ModalAward showModal={showModalAward} handleCloseModal = {handleCloseModalAward} editState={editState} data={data} handleReload={setReload}/>
            <ModalPromotions showModal={showModalPromotions} handleCloseModal = {handleCloseModalPomotions} editState={editState} data={data} handleReload={setReload}/>
            <ModalTopThree showModal={showModalTopThree} handleCloseModal = {handleCloseModalTopThree} _data={data}/>
            <ModalWinningNumbers showModal={showModalWinningNumbers} handleCloseModal = {handleCloseModalWinningNumber} data={data}/>
            <ModalWinningNumbersResult showModal={showModalWinningNumbersResult} handleCloseModal = {handleCloseModalWinningNumberResult} data={data}/>

            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" disabled={!checkPermission(2,1)} title="Insere um novo registro" onClick={e => {setEditState(false) 
                                                                                                               setShowModal(true)}}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,1)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/raffle' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Sorteios"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackCellClick   = {editPermission ? undefined : EditRaffle}
                      callbackButtons     = {[RemoveRaffle, 
                                              EditRaffle,
                                              IncreaseNumber,
                                              closeRaffle,
                                              searchAward,
                                              finnancial,
                                              promotions,
                                              topThree,
                                              winningNumbers,
                                              winningNumbersResult
                                            ]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}