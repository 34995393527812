import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';

import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import CurrencyInput from './../../components/moneyInput';

import { v4 as uuidv4 } from 'uuid';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'
class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalPromotion({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]      = useState(false)

    const [numbers, setNumbers]      = useState(1)
    const [value, setValue]          = useState(0)
    const [promotion, setPromotion]  = useState([])

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-promotion'),document.querySelector('#submit-promotion').children[0].classList,document.querySelector('#submit-promotion').lastChild.nodeValue)
        await api.post('/set-promotion', {promotion, idraffle : data.idraffle})
                 .then(response => {
                    console.log('---')
                    ShowMessages(response)
                    if (response.ok) {  
                        if (response.data.status === 200) {
                            handleCloseModal()  
                            handleReload(true)    
                        } else 
                            loadingButton(false,document.querySelector('#submit-promotion'))
                    } else
                        loadingButton(false,document.querySelector('#submit-promotion'))
                })
                .catch( error => {
                    loadingButton(false,document.querySelector('#submit-promotion'))
                    ShowMessages(error) 
                })
    }
    
    const addPromotion = () => {
        if ((numbers === '') || (numbers <= 1)) {
            ShowMessages({status : 500, message : "Informe uma quantidade de números válida"})
            return
        }

        if ((value === '') || (value <= 0)) {
            ShowMessages({status : 500, message : "Informe um valor válido para a promoção"})
            return
        }

        setPromotion(promotion => promotion.concat({ id : uuidv4(), 
                                                     numbers,
                                                     value})) 
        setNumbers('')    
        setValue(0)
    }

    const removePromotion = (id) => {
        const _remove = promotion.filter((t, i) => {
            if (t.id !== id)
                return true;
        
            return false;
        });
        setPromotion(_remove)
    }

    useEffect(() => {
     //   setPromotion(data.promotions || [])
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-promotion')
    } ,[])

   

    return (
        <Modal size='sm' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog} dialogClassName="width550">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-percent"></i> Promoções
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div>
                        <ValidationForm> 
                            <div style={{display:"flex",gap:'5px'}}>
                                <div>
                                    <span>Números</span>
                                    <input className='form-control' type="number" value={numbers} onChange={e => setNumbers(e.target.value)}/>
                                </div>
                                <div style={{display:"flex",gap:'5px'}}>
                                    <div>
                                        <span>Valor</span>
                                        <CurrencyInput className='form-control' type="text" value={value} onChange={setValue}/>
                                    </div>
                                <div     style={{marginTop:"17px"}}>
                                        <button type="button" className='btn btn-success' onClick={e => addPromotion()}><i className='fa fa-plus'/></button>
                                    </div>
                                </div>
                            </div>
                        </ValidationForm>

                        <div>
                            <div style={{display:"flex", marginTop:"10px", background:"#cacaca", padding:"5px", fontWeight:"bold"}}>
                                <div style={{width:"100px"}}><span>Números</span></div>
                                <div style={{width:"100px"}}><span>Valor</span></div>
                            </div>
                            {promotion.map(_number => {
                                return (
                                    <div style={{display:"flex", padding:"3px 5px"}}>
                                        <div style={{width:"100px", display:"flex", alignItems:"center"}}>{_number.numbers}</div>
                                        <div style={{width:"100px", marginLeft:"10px", display:"flex", alignItems:"center"}}>{_number.value}</div>
                                        <div style={{width:"80px", display:"flex", justifyContent:"center"}}>
                                            <button className='btn btn-sm btn-danger' onClick={e => removePromotion(_number.id)}><i className='fa fa-ban'/></button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-promotion" onClick={e => handleSubmit(e)} className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}